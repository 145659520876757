<template>
  <div class="input">
    <h2 class="input__title">Time Picker</h2>
    <div class="input__wrap">
      <h3 class="input__subtitle">Input Default</h3>
      <TimePicker v-model="time" />
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Time Picker Props</h3>
      <ul class="input__list">
        <li class="input__item"><span>alt</span> - time picker с выпадающим списком</li>
      </ul>
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Input Alt</h3>
      <TimePicker v-model="time" :alt="true" :value="time" />
    </div>
  </div>
</template>

<script>
import TimePicker from "@/modules/UiKit/components/TimePicker/TimePicker";
export default {
  name: "TimePickerPage",
  mixins: [],
  props: [],
  components: { TimePicker },
  data() {
    return {
      time: ""
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;

    &_textarea {
      margin-top: 48px;
    }
  }

  &__subtitle {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
    color: $text-default;
  }

  &__content {
    margin: 24px 0 48px;
  }

  &__wrap {
    width: 48%;
    margin: 24px 0;

    &_default {
      width: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: bold;
    }
  }
}
</style>
